<template>

<!-- <div class="layout-dashboard"> -->

<div class="grid">

<div class="col-12 md:col-12 xl:col-9">
<!-- <div class="grid"> -->

  <div class="card">
    <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" />
    <div>
			<DynamicDialog />
		</div>
    <Menu ref="menu1" :popup="true" :model="items"></Menu>
    <!-- <Button class="p-button-sm mr-1" icon="pi pi-sync" label="新着" @click="refreshNewsData" :loading="loading" style="width: auto"/> -->
    <!-- <SplitButton icon="pi pi-sync" label="新着" :model="button_items" class="p-button-sm mr-1 mb-1" @click="refreshNewsData" :loading="loading"/> -->
    <DataView :value="dataviewValue" :layout="layout" :key="index">
      <template #header>
        <!-- <div class="grid grid-nogutter justify-content-between"> -->
        <div>
          <div class="grid grid-nogutter justify-content-between">
            <div>
              <Button class="p-button-sm" icon="pi pi-sync" label="Refresh" @click="refreshNewsData" :loading="loading" style="width: auto"/>
              <!-- <SplitButton class="p-button-sm" icon="pi pi-sync" :model="chunck_items" label="最新" @click="refreshNewsData" :loading="loading" style="width: auto"/> -->
              <!-- <Dropdown v-model="selectedChunk" :options="chunks" optionLabel="name" placeholder="20" /> -->
              <!-- <Button class="p-button-sm  ml-1" icon="pi pi-search" label="検索" @click="refreshNewsData" :loading="loading" style="width: auto"/> -->
              <!-- <ToggleButton class="p-button-sm ml-3" v-model="checked_meigara" onLabel="" offLabel="" onIcon="pi pi-building" offIcon="pi pi-building" @click="toggle_MegiaraDisplay"/>
              <ToggleButton class="p-button-sm ml-1" v-model="checked_tag" onLabel="" offLabel="" onIcon="pi pi-tag" offIcon="pi pi-tag" @click="toggle_TagDisplay"/>
              <ToggleButton class="p-button-sm ml-1" v-model="checked_grid" onLabel="" offLabel="" onIcon="pi pi-th-large" offIcon="pi pi-th-large" @click="toggle_GridDisplay"/> -->
            </div>
            <div>
              <!-- <ToggleButton class="p-button-sm ml-1" v-model="checked_search" onLabel="" offLabel="" onIcon="pi pi-search" offIcon="pi pi-search" @click="toggle_SearchDisplay"/> -->
              <ToggleButton class="p-button-sm ml-1" v-model="checked_tag" onLabel="" offLabel="" onIcon="pi pi-tags" offIcon="pi pi-tags" @click="toggle_TagDisplay"/>
              <ToggleButton class="p-button-sm ml-1" v-model="checked_meigara" onLabel="" offLabel="" onIcon="pi pi-building" offIcon="pi pi-building" @click="toggle_MegiaraDisplay"/>
              <ToggleButton class="p-button-sm ml-1" v-model="checked_grid" onLabel="" offLabel="" onIcon="pi pi-th-large" offIcon="pi pi-th-large" @click="toggle_GridDisplay"/>
              <!-- <Button icon="pi pi-arrow-down" class="p-button-sm p-button-outlined ml-1" @click="scrollToBottom"/> -->
              <!-- <Button icon="pi pi-arrow-down" class="p-button-sm p-button-outlined ml-1" @click="scrollToBottom"/> -->
            </div>
          </div>
          <!-- <DataViewLayoutOptions style="text-align: left" v-model="layout"/> -->
        </div>
        <div v-if="show_search" class="mt-2">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
							<i class="pi pi-search"></i>
						</span>
            <!-- <span class="p-fluid"> -->
              <AutoComplete v-model="selectedMeigara" :suggestions="filteredMeigaras" 
              @complete="searchMeigara($event)" @item-select="onObjectClick('meigara', selectedMeigara.substr(0,4))"
              optionLable="name" placeholder="銘柄コード、企業名"/>
            <!-- </span> -->
         </div>
          <!-- <Dropdown v-model="selectedChunk" :options="chunks" optionLabel="name" placeholder="20" /> -->
          <!-- <Button class="p-button-sm" icon="pi pi-search" label="検索" @click="refreshNewsData" :loading="loading" style="width: auto"/>
          <ToggleButton class="p-button-sm ml-1" v-model="checked_search" onLabel="" offLabel="" onIcon="pi pi-search" offIcon="pi pi-search" @click="toggle_SearchDisplay"/> -->
        </div>
      </template>
      <template #list="slotProps">
        <div class="col-12">
          <div class="card border-0">
            <div class="flex align-items-center justify-content-between">
              <div>    
                <a href="#" @click.prevent.stop="onObjectClick('sentiment', slotProps.data.news_sentiment)">
                  <span :class="'sentiment-badge status-' + getBadgeStatusText(slotProps.data.news_sentiment)">{{getFaceSymbol(slotProps.data.news_sentiment)}}</span>
                </a>
                <a class="ml-1" v-bind:href="slotProps.data.news_url" target="_blank">{{ slotProps.data.news_overview }}
                  ｜ {{slotProps.data.meigaras[0].market_name_short}} {{ slotProps.data.meigaras[0].meigara_cd }} {{ slotProps.data.meigaras[0].meigara_name }}
                  <span v-if="slotProps.data.meigaras_cnt > 1">
                  など{{slotProps.data.meigaras_cnt}}銘柄
                  </span>
                </a>
              </div>
            </div>
            <!-- <div v-if="show_tag == true" class="flex align-items-center justify-content-between"> -->
            <div v-if="show_tag == true">
              <i class="pi pi-tags mr-1 mt-1"/>
              
              <!-- <Chip class="chip-r mr-1"> {{ slotProps.data.news_type }} </Chip> -->
              <Chip class="chip-r mr-1 mb-1" :class="getChipClass(slotProps.data.news_type)">
                <a href="#" @click.prevent.stop="onObjectClick('type', slotProps.data.news_type)">
                  <span :class="getChipTextClass(slotProps.data.news_type)">{{ slotProps.data.news_type }}</span>
                </a>
              </Chip>

              <template v-for="(tag, index) in slotProps.data.news_tags" :key="index">
                <Chip class="chip-r mr-1 mb-1" :class="getChipClass(tag)">
                  <a href="#" @click.prevent.stop="onObjectClick('tag', tag)">
                    <span :class="getChipTextClass(tag)">{{tag}}</span>
                  </a>
                </Chip>
              </template>
            </div>
            <div class="flex align-items-center justify-content-between">
              <div>
                <!-- <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-1rem mr-1" @click="toggle_setMeigaras($event, slotProps.data)"></Button> -->
                <!-- <i class="pi pi-clock mt-1 mr-1"/> -->
                <!-- <span class="subinfo-text">{{ slotProps.data.news_datetime.slice(-14) }} {{ slotProps.data.info_source }}｜</span> -->
                <span class="subinfo-text">{{ slotProps.data.news_datetime.slice(-14) }} {{ slotProps.data.info_source }}</span>
                <!-- <span class="subinfo-text ml-1">|</span> -->
                  <!-- <i class="pi pi-search-plus ml-2 mr-1"/> -->

                  &nbsp;<a href="#" @click.prevent.stop="toggle_MegiaraDisplay_Each(slotProps.index)">
                  <!-- <span class="subinfo-link-text mr-1">({{slotProps.data.meigaras_up_cnt}}⤴︎{{slotProps.data.meigaras_down_cnt}}⤵︎)</span> -->
                  
                  <span class="subinfo-link-text span-highlight">
                    ({{slotProps.data.meigaras_up_cnt}}⤴︎{{slotProps.data.meigaras_down_cnt}}⤵︎) {{slotProps.data.meigaras_v1d_mean}}%
                    <!-- <span :class="getPriceClass2_up(slotProps.data)">{{slotProps.data.meigaras_up_cnt}}⤴︎</span><span :class="getPriceClass2_down(slotProps.data)">{{slotProps.data.meigaras_down_cnt}}⤵︎</span><span :class="getPriceClass(slotProps.data.meigaras_v1d_mean)">{{slotProps.data.meigaras_v1d_mean}}%</span> -->
                    <!-- <span :class="getPriceClass(slotProps.data.meigaras_v1d_mean)">{{slotProps.data.meigaras_v1d_mean}}%</span> -->
                  </span>
                  <i :class="getPlusMinusClass(slotProps.index)"/>
                </a>
              </div>
              <div>
                <!-- <a href="#" @click.prevent.stop="onShow(slotProps.data)">
                  <i class="pi pi-chart-line"/> {{slotProps.data.meigaras_cnt}}
                </a> <span class="subinfo-text">|</span>
                <a href="#" @click.prevent.stop="onShow(slotProps.data)">
                  <i class="pi pi-link ml-1"/> R
                </a> <span class="subinfo-text">|</span>
                <a href="#" @click.prevent.stop="toggle_setMeigaras($event, slotProps.data)">
                <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="toggle_setMeigaras($event, slotProps.data)"></Button>
                  <i class="pi pi-ellipsis-h ml-1"/>
                </a> -->
                <!-- <span class="subinfo-text ml-1">前日比</span>
                <span class="plus-text ml-1">{{slotProps.data.meigaras_cnt}}⤴︎</span>
                <span class="minus-text ml-1">2⤵︎</span> -->
                <!-- <span class="subinfo-text">@{{ slotProps.data.odate.slice(-5) }} {{ slotProps.data.otime }}</span> -->
                <a href="#" @click.prevent.stop="onShow(slotProps.data)">
                  <!-- <i class="pi pi-window-maximize mr-1"/> -->
                  <!-- <i class="pi pi-share-alt icon-button-default"/> -->
                  <i :class="getPlusMinusClass3(slotProps.index)"/>
                  <!-- <span class="subinfo-link-text">詳細</span> -->
                </a>
              </div>
            </div>
            <div v-if="slotProps.data.show_meigaras == true">
              <i class="pi pi-building mr-1 mb-3"/>
              <a href="#" @click.prevent.stop="toggle_MegiaraChartDisplay_Each(slotProps.index)">
                <span v-if="!dataviewValue[slotProps.index].show_meigaras_chart" class="subinfo-link-text span-highlight">トレンドを見る</span>
                <span v-else class="subinfo-link-text span-highlight">トレンドを隠す</span>
                <i class="span-highlight" :class="getPlusMinusClass2(slotProps.index)"/>
              </a>
              <span class="subinfo-text"> ({{ slotProps.data.odate.slice(-8) }} {{ slotProps.data.otime }} 株価)</span>
              <div v-if="slotProps.data.show_meigaras_chart == false">
                <template v-for="(meigara, index) in slotProps.data.meigaras" :key="index">
                  <Chip class="chip-r mr-1 mb-1" :class="getChipClass(meigara.meigara_cd)">
                    <!-- class="chip-r mr-1 mb-1" -->
                    <a href="#" @click.prevent.stop="onObjectClick('meigara', meigara.meigara_cd)">
                      <span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">{{meigara.market_name_short}} {{meigara.meigara_cd}} {{meigara.meigara_name}} ¥{{meigara.close}}</span>
                    </a>								
                    <span :class="getPriceClass(meigara.v1d_mean)">{{meigara.v1d_mean}}%</span>
                  </Chip>
                </template>
                <a href="#" @click.prevent.stop="copyToClipboard(slotProps.data.meigaras)">
                  <i class="pi pi-copy ml-1"/>
                </a>
              </div>
              <div v-else>
                <template v-for="(meigara, index) in slotProps.data.meigaras" :key="index">
                  <Chip class="chip-r mr-1 mb-1" :class="getChipClass(meigara.meigara_cd)">
                    <a href="#" @click.prevent.stop="onObjectClick('meigara', meigara.meigara_cd)">
                      <span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">{{meigara.market_name_short}} {{meigara.meigara_cd}} {{meigara.meigara_name}} ¥{{meigara.close}}</span>									
                    </a>
                      <span :class="getPriceClass(meigara.v1d_mean)">{{meigara.v1d_mean}}%</span>
                  </Chip>
                  <table>
                    <tr>
                      <td class="rotate_text" vAlign="bottom">
                        <span class="date-span">{{getTrendchartDate(meigara.meigara_trendchart_sdate)}}</span>
                      </td>
                      <td>
                        <!-- <router-link :to="{name:'meigarachart',params:{code:meigara.meigara_cd}}"> -->
                          <div class="box">
                            <img class="trendchart-img" :src="'https://tradersr.com' + meigara.meigara_trendchart_file"/>
                            <a href="#" @click.prevent.stop="onShow(slotProps.data)">
                              <i class="pi pi-window-maximize span-highlight center"/>
                            </a>
                          </div>
                        <!-- </router-link> -->
                      </td>
                      <td class="rotate_text"  vAlign="top" style="padding:0">
                        <span class="date-span">{{getTrendchartDate(meigara.meigara_trendchart_edate)}}</span>
                      </td>
                      <!-- <td vAlign="top" style="padding:0">
                        <a href="#" @click.prevent.stop="onShow(slotProps.data)">
                          <i class="pi pi-window-maximize span-highlight"/>
                        </a>
                      </td> -->
                    </tr>
                  </table>
                </template>
                <a href="#" @click.prevent.stop="copyToClipboard(slotProps.data.meigaras)">
                  <i class="pi pi-copy ml-1"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #grid="slotProps">
        <div class="col-12 md:col-3">
          <div class="card m-1 border-0">
            {{ slotProps.data.news_datetime }} {{ slotProps.data.news_overview }}
          </div>
        </div>
      </template>
      
      <template #footer>
        <div class="flex align-items-center justify-content-between">
          <!-- <Button v-if="data_existing == true" :label="button_more_label" icon="pi pi-refresh" class="p-button-sm mt-1" @click="incrementNewsData" :loading="loading_more"/> -->
          <Button v-if="data_existing == true" label="もっと見る" icon="pi pi-refresh" class="p-button-sm mt-1" @click="incrementNewsData" :loading="loading_more"/>
          <Button icon="pi pi-arrow-up" class="p-button-sm p-button-outlined" @click="scrollToTop"/>
        </div>
      </template>
    </DataView>
    <!-- <div class="flex align-items-center justify-content-between"> -->
      <!-- <div>
        <Button icon="pi pi-arrow-up" class="p-button-sm mr-1" @click="scrollToTop"/>
        <Button v-if="data_existing == true" :label="button_more_label" icon="pi pi-refresh" class="p-button-sm mt-1" @click="incrementNewsData" :loading="loading_more"/>
      </div> -->
      <!-- <Button icon="pi pi-arrow-up" class="p-button-sm p-button-outlined mt-1" @click="scrollToTop"/> -->
    <!-- <div>
      <Chip class="chip-r chip-highlight mt-1">
        {{lastSearchKey}}
      </Chip>
    </div> -->
  </div>

</div>

<div class="col-12 md:col-12 xl:col-3">
  <div class="card">
    perf {{ perf }}
    <div class="card-header mt-2">
      <h6>Navigator</h6>
      <!-- <div class="header-buttons">
          <a href="#"><i class="pi pi-arrow-up"></i></a>
      </div> -->
    </div>
    <div>
      <!-- <a href="#"> -->
      <i class="pi pi-filter mt-2"></i>
      <!-- </a> -->
      キーワード
      <div>
        <Chip class="chip-r chip-highlight">
          <span v-if="lastSearchKey == 0">All</span>
          <span v-else>{{lastSearchKey}}</span>
        </Chip>
      </div>
    </div>
    <div>
      <i class="pi pi-clock mt-3 mr-1"></i>
      時刻
      <div>
        {{last_dt}}
      </div>
      <div>
        {{first_dt}}
      </div>
    </div>
    <div>
      <i class="pi pi-map mt-3 mr-2"></i>
      <span>ニュース</span>
      <div v-if="dataviewValue != null">
        <a href="#" @click.prevent.stop="onObjectClick('sentiment', 'P')">
          <span v-if="!countSentimentDict['P']" :class="'sentiment-badge status-p'">好 (0)</span>
          <span v-else :class="'sentiment-badge status-p'">好 ({{ countSentimentDict['P'] }})</span>
        </a>

        <a href="#" @click.prevent.stop="onObjectClick('sentiment', 'N')">
          <span v-if="!countSentimentDict['N']" :class="'sentiment-badge status-n ml-1'">悪 (0)</span>
          <span v-else :class="'sentiment-badge status-n ml-1'">悪 ({{ countSentimentDict['N'] }})</span>
        </a>

        <a href="#" @click.prevent.stop="onObjectClick('sentiment', 'B')">
          <span v-if="!countSentimentDict['B']" :class="'sentiment-badge status-b ml-1'">？ (0)</span>
          <span v-else :class="'sentiment-badge status-b ml-1'">？ ({{ countSentimentDict['B'] }})</span>
        </a>

        <a href="#" @click.prevent.stop="onObjectClick('sentiment', 'U')">
          <span v-if="!countSentimentDict['U']" :class="'sentiment-badge status-u ml-1'">未 (0)</span>
          <span v-else :class="'sentiment-badge status-u ml-1'">未 ({{ countSentimentDict['U'] }})</span>
        </a>

        <span> Total: {{dataviewValue.length}}</span>

        <div>
          <Button v-if="data_existing == true" :label="button_more_label2" icon="pi pi-refresh" class="p-button-sm p-button-text" @click="incrementNewsData" :loading="loading_more"/>
        </div>
      </div>
    </div>

    <div>
      <i class="pi pi-sitemap mt-3 mr-2"></i>
      <!-- <span class="subinfo-text">カテゴリ</span> -->
      <span>カテゴリ</span>
      <div>
        <template v-for="(type, index) in countNewsTypeDict" :key="index">
          <Chip v-if="index < 10 || !moreNewsType" class="chip-r mb-1 mr-1" :class="getChipClass(type[0])">
            <a href="#" @click.prevent.stop="onObjectClick('type', type[0])">
              <span :class="getChipTextClass(type[0])">{{type[0]}} ({{type[1]}})</span>
            </a>
          </Chip>
        </template>
        <span v-if="countNewsTypeDict.length > 10">
          <a v-if="!moreNewsType" href="#" @click.prevent.stop="toggle_moreNewsType()"><span class="span-highlight">less</span></a>
          <a v-else href="#" @click.prevent.stop="toggle_moreNewsType()"><span class="span-highlight">+{{ countNewsTypeDict.length - 10 }} more</span></a>
        </span>
      </div>
    </div>

    <div>
      <i class="pi pi-tag mt-3 mr-2"></i>
      <span>感情タグ</span>
      <div>
        <template v-for="(tag, index) in countNewsTagsDict" :key="index">
          <a v-if="index < 10 || !moreNewsTags" href="#" @click.prevent.stop="onObjectClick('tag', tag[0])">
            <!-- <Chip v-if="index < 10 || !moreNewsTags" class="chip-r mb-1 mr-1"> -->
            <Chip v-if="index < 10 || !moreNewsTags" class="chip-r mb-1 mr-1" :class="getChipClass(tag[0])">
              <a href="#" @click.prevent.stop="onObjectClick('tag', tag[0])">
                <span :class="getChipTextClass(tag[0])">
                  {{tag[0]}} ({{tag[1]}})
                </span>
              </a>
            </Chip>
         </a>
        </template>
        <span v-if="countNewsTagsDict.length > 10">
          <a v-if="!moreNewsTags" href="#" @click.prevent.stop="toggle_moreNewsTags()"><span class="span-highlight">less</span></a>
          <a v-else href="#" @click.prevent.stop="toggle_moreNewsTags()"><span class="span-highlight">+{{ countNewsTagsDict.length - 10 }} more</span></a>
        </span>
      </div>
    </div>

    <div>
      <i class="pi pi-building mt-3 mr-2"></i>
      <span>銘柄</span>
      <div>
        <template v-for="(meigara, index) in countMeigarasDict" :key="index">
          <Chip v-if="index < 10 || !moreMeigaras" class="chip-r mb-1 mr-1" :class="getChipClass(meigara[0].substr(0,4))">
            <a href="#" @click.prevent.stop="onObjectClick('meigara', meigara[0].substr(0,4))">
              <span :class="getChipTextClass(meigara[0].substr(0,4))">{{meigara[0]}} ({{meigara[1]}})</span>
            </a>
          </Chip>
        </template>
        <span v-if="countMeigarasDict.length > 10">
          <a v-if="!moreMeigaras" href="#" @click.prevent.stop="toggle_moreMeigaras()"><span class="span-highlight">less</span></a>
          <a v-else href="#" @click.prevent.stop="toggle_moreMeigaras()"><span class="span-highlight">+{{ countMeigarasDict.length - 10 }} more</span></a>
        </span>
      </div>
    </div>

    <div class="flex align-items-center justify-content-between">
      <span></span>
      <Button icon="pi pi-arrow-up" class="p-button-sm p-button-outlined" @click="scrollToTop"/>
    </div>
    <!-- <Button v-if="data_existing == true" :label="button_more_label" icon="pi pi-refresh" class="p-button-outlined mt-1" @click="incrementNewsData" :loading="loading_more"/> -->
    <!-- <div class="flex align-items-center justify-content-between"> -->
    <!-- <div>
      <div>
        <Button v-if="data_existing == true" :label="button_more_label" icon="pi pi-refresh" class="p-button-outlined mt-1" @click="incrementNewsData" :loading="loading_more"/>
      </div>
    </div> -->

  </div>
</div>

</div>

<!-- </div> -->

<!-- </div> -->
</template>

<script>
import Meigaranews2listService from "@/service/Meigaranews2listService";
import MeigarasearchExtService from '@/service/MeigarasearchExtService';
import News from '@/components/News';

export default {
  data() {
    return {
      layout: 'list', // l(list), g(grid)
      dataviewValue: null,
      last_dt: null,
      first_dt: null,
      // last_id: 0,
      last_tid: 0,
      chunk_size: 20,
      lastSearchObject: 'All',
      lastSearchKey: 0,
      loading: false,
      loading_more: false,
      data_existing: true,
      perf: null,
      show_meigara: false,
      show_search: true,
      show_tag: true,
      clickedNews: null,
      selectedCodeKey: 0,
      copiedMeigaras: null,
      selectedNewsId: null,
      selectedPropsData: null,
      checked_meigara: false,
      checked_search: true,
      checked_tag: true,
      checked_grid: false,
      countSentimentDict: {},
      countNewsTypeDict: {},
      countNewsTagsDict: {},
      countMeigarasDict: {},
      moreNewsType: true,
      moreNewsTags: true,
      moreMeigaras: true,
      button_label: '最新',
      filteredMeigaras: null,
      currentMeigara: null,
      selectedMeigara: null,
      // selectedMeigaraCd: null,
      // selectedMeigaraName: null,
      // showTrend: false,
      // lastclicked_meigara_icon: false, 
      selectedChunk: null,
      button_more_label: '+20 もっと見る',
      button_more_label2: '+20',
      chunks: [
        {name: '20', code: '20'},
        {name: '40', code: '40'},
        {name: '100', code: '100'}
      ],
      button_items: [
        {
          label: '銘柄を表示',
          command: () => {
            this.show_meigara = true;
          }
        },
        {
          label: '銘柄を非表示',
          command: () => {
            this.show_meigara = false;
          }
        },
      ],
      chunck_items: [
        {
          label: '20 件を見る',
          command: () => {
            this.chunk_size = 20;
            this.refreshNewsData();
            // this.button_label = '最新 20件';
          }
        },
        {
          label: '40 件を見る',
          command: () => {
            this.chunk_size = 40;
            this.refreshNewsData();
            // this.button_label = '最新 40件';
          }
        },
        {
          label: '100 件を見る',
          command: () => {
            this.chunk_size = 100;
            this.refreshNewsData();
            // this.button_label = '最新 100件';
          }
        },
      ],
      items: [
        {
          label: '銘柄をコピー',
          icon: 'pi pi-copy',
          command: () => {
            // this.$router.push('/meigarasearch_ext/' + this.selectedBookmarkCd);
            this.copyToClipboard(this.copiedMeigaras);
          }
        },
        {
          label: 'Rインサイト',
          icon: 'pi pi-window-maximize',
          command: () => {
            // this.$router.push('/meigarasearch_ext/' + this.selectedBookmarkCd);
            this.onShow(this.selectedPropsData);
          }
        },
      ],
    }
  },
  meigaranews2listService: null,
  meigarasearchExtService: null,
  created() {
    this.meigaranews2listService = new Meigaranews2listService();
    this.meigarasearchExtService = new MeigarasearchExtService();
  },
  mounted() {
    console.log('Meigaranews2list.vue mounted');

    this.refreshNewsData();
  },
  methods: {
    getChipClass(key){
      return [
        {
          // 'a-active chip-r mr-1 mb-1':this.lastSearchKey===key,
          // 'chip-r mr-1 mb-1':this.lastSearchKey!==key,
          'a-active':this.lastSearchKey===key,
        }
      ]
    },
    getChipTextClass(key){
      return [
        {
          'text-active':this.lastSearchKey===key,
          // 'text-active mr-1':this.lastSearchKey===key,
          // 'mr-1':this.lastSearchKey!==key,
        }
      ]
    },
    onObjectClick(otype, key){
      this.loading = true;
      this.meigaranews2listService.getNewsInfo(0, this.chunk_size, key, otype)
				.then(d => {
          if(d.data.newslist){
            this.dataviewValue = d.data.newslist;
            this.updateCountDict();

            this.first_dt = d.data.newslist[0].news_datetime;
            this.last_dt = d.data.newslist.slice(-1)[0].news_datetime;
            // this.last_id = d.data.newslist.slice(-1)[0].id;
            this.last_tid = d.data.newslist.slice(-1)[0].tid;

            // if(d.data.newslist.length == 20){
            if(d.data.newslist.length == this.chunk_size){
              this.data_existing = true;
              this.button_more_label = '+' + this.chunk_size + ' もっと見る';
              this.button_more_label2 = '+' + this.chunk_size;
            }else{
              this.data_existing = false;
            }
          }else{
            this.data_existing = false;
          }

					this.perf = d.perf;
					this.loading = false;

          this.lastSearchObject = otype;
          this.lastSearchKey = key;

          this.scrollToTop();
          
					console.log(d);
				});

      this.selectedMeigara = null;
    }
    ,
    refreshNewsData() {
			this.loading = true;
			this.meigaranews2listService.getNewsInfo(0, this.chunk_size, 0, 'all')
				.then(d => {
          if(d.data.newslist){
            this.dataviewValue = d.data.newslist;
            this.updateCountDict();

            this.first_dt = d.data.newslist[0].news_datetime;
            this.last_dt = d.data.newslist.slice(-1)[0].news_datetime;
            // this.last_id = d.data.newslist.slice(-1)[0].id;
            this.last_tid = d.data.newslist.slice(-1)[0].tid;

            // if(d.data.newslist.length == 20){
            if(d.data.newslist.length == this.chunk_size){
              this.data_existing = true;
              this.button_more_label = '+' + this.chunk_size + ' もっと見る';
              this.button_more_label2 = '+' + this.chunk_size;
            }else{
              this.data_existing = false;
            }
          }else{
            this.data_existing = false;
          }

					this.perf = d.perf;
					this.loading = false;

          this.lastSearchObject = 'all';
          this.lastSearchKey = 0;
          
					console.log(d);
				});
		},
    incrementNewsData() {
			this.loading_more = true;
      // let d = null;
      // d = this.meigaranews2listService.getNewsInfo(this.last_dt, this.last_id, this.lastSearchKey, this.lastSearchObject);
      // console.log(this.last_dt);
      // console.log(this.last_id);
      console.log(this.last_tid);

      // this.meigaranews2listService.getNewsInfo(this.last_dt, this.last_id, this.lastSearchKey, this.lastSearchObject)
      this.meigaranews2listService.getNewsInfo(this.last_tid, this.chunk_size, this.lastSearchKey, this.lastSearchObject)
				.then(d => {
          if(d.data.newslist){
            let mergedValue = [...this.dataviewValue, ...d.data.newslist];
            this.dataviewValue = mergedValue;
            this.updateCountDict();

            // this.last_dt = d.data.newslist.slice(-1)[0].news_datetime;
            // this.last_tid = d.data.newslist.slice(-1)[0].tid;

            this.last_dt = this.dataviewValue[this.dataviewValue.length-1].news_datetime;
            this.last_tid = this.dataviewValue[this.dataviewValue.length-1].tid;

            // if(d.data.newslist.length == 20){
            if(d.data.newslist.length == this.chunk_size){
              this.data_existing = true;
              this.button_more_label = '+' + this.chunk_size + ' もっと見る';
              this.button_more_label2 = '+' + this.chunk_size;
            }else{
              this.data_existing = false;
            }
          }else{
            this.data_existing = false;
          }

          this.perf = d.perf;
          this.loading_more = false;

          console.log(d);
        });
		},
    getBadgeStatusText(key){
      return key.toLowerCase();
    },
    getFaceSymbol(sentiment) {
      let ret = null;

      if(sentiment == 'P'){
        ret = '好';
      }else if(sentiment == 'N'){
        ret = '悪';
      }else if(sentiment == 'B'){
        ret = '？';
      }else{
        ret = '未';
        // ret = null;
      }

      return ret;
    },
    getPriceClass(data) {
      return [
        {
          'positive': data > 0,
          'negative': data < 0,
          'neutral': data == 0,
        }
      ];
    },
    getPriceClass2_up(data) {
      return [
        {
          'positive': data.meigaras_up_cnt > data.meigaras_down_cnt,
          // 'negative': data.meigaras_up_cnt < data.meigaras_down_cnt,
          'neutral': data.meigaras_up_cnt == data.meigaras_down_cnt,
        }
      ];
    },
    getPriceClass2_down(data) {
      return [
        {
          // 'positive': data.meigaras_up_cnt > data.meigaras_down_cnt,
          'negative': data.meigaras_up_cnt < data.meigaras_down_cnt,
          'neutral': data.meigaras_up_cnt == data.meigaras_down_cnt,
        }
      ];
    },
    getPlusMinusClass(nidx) {
      return [
        {
          'pi pi-search-plus span-highlight ml-1': this.dataviewValue[nidx].show_meigaras == false,
          'pi pi-search-minus span-highlight ml-1': this.dataviewValue[nidx].show_meigaras == true,

          // 'pi pi-search-plus icon-button-sm-red ml-1': this.dataviewValue[nidx].show_meigaras == false && 
          //                                               this.dataviewValue[nidx].meigaras_v1d_mean > 0,
          // 'pi pi-search-minus icon-button-sm-red ml-1': this.dataviewValue[nidx].show_meigaras == true &&
          //                                               this.dataviewValue[nidx].meigaras_v1d_mean > 0,
          // 'pi pi-search-plus icon-button-sm-blue ml-1': this.dataviewValue[nidx].show_meigaras == false && 
          //                                               this.dataviewValue[nidx].meigaras_v1d_mean < 0,
          // 'pi pi-search-minus icon-button-sm-blue ml-1': this.dataviewValue[nidx].show_meigaras == true && 
          //                                               this.dataviewValue[nidx].meigaras_v1d_mean < 0,
          // 'pi pi-search-plus icon-button-sm-yellow ml-1': this.dataviewValue[nidx].show_meigaras == false && 
          //                                               this.dataviewValue[nidx].meigaras_v1d_mean == 0,
          // 'pi pi-search-minus icon-button-sm-yellow ml-1': this.dataviewValue[nidx].show_meigaras == true && 
          //                                               this.dataviewValue[nidx].meigaras_v1d_mean == 0,
        }
      ];
    },
    getPlusMinusClass2(nidx) {
      return [
        {
          'pi pi-angle-double-down ml-1 mt-1 mb-2': this.dataviewValue[nidx].show_meigaras_chart == false,
          'pi pi-angle-double-up ml-1 mt-1 mb-2': this.dataviewValue[nidx].show_meigaras_chart == true,
        }
      ];
    },
    getPlusMinusClass3(nidx) {
      return [
        {
          'pi pi-share-alt icon-button-positive': this.dataviewValue[nidx].meigaras_v1d_mean > 0,
          'pi pi-share-alt icon-button-negative': this.dataviewValue[nidx].meigaras_v1d_mean < 0,
          'pi pi-share-alt icon-button-neutral': this.dataviewValue[nidx].meigaras_v1d_mean == 0,
        }
      ]      
    },
    getPlusMinusClass4(mean) {
      return [
        {
          'pi pi-window-maximize icon-button2-positive': mean > 0,
          'pi pi-window-maximize icon-button2-negative': mean < 0,
          'pi pi-window-maximize icon-button2-neutral': mean == 0,
        }
      ]
    },
    getTrendchartDate(param) {
      let tcdate = null;
      if(param != null && param.length == 10){
        tcdate = param.substr(5, 5);
        tcdate = tcdate.replace('-','/');
      }
      return tcdate;
		},
    onShow(params) {
      this.clickedNews = params;
      console.log(this.clickedNews);

      this.$dialog.open(News, {
        props: {
          header: 'News Insight',
          style: {
              width: '75vw',
          },
          breakpoints:{
              '960px': '75vw',
              '640px': '90vw'
          },
          modal: true,
          dismissableMask: true
        },
        data: {
          newsData: this.clickedNews ? this.clickedNews : null,
          selectedCodeKey: this.selectedCodeKey ? this.selectedCodeKey : 0,
          layout: this.layout ? this.layout : null,
          caller: 'meigaranews2list',
          selectedNewsId: this.selectedNewsId ? this.selectedNewsId : null,
        }
      });			
    },
    copyToClipboard(meigaras){
      // console.log(meigaras);
      this.clipboardText = '';
      for(let i=0; i<meigaras.length; i++) {
        if(i != meigaras.length-1){
          this.clipboardText += meigaras[i].meigara_cd + ", ";
        }else{
          this.clipboardText += meigaras[i].meigara_cd;
        }
      }
      // console.log(this.clipboardText);
      navigator.clipboard.writeText(this.clipboardText);
      // console.log("copied");
      this.$toast.add({severity:'success', summary: '銘柄コピー', detail:'クリップボードに' + meigaras.length + '銘柄をコピーしました (' + this.clipboardText + ')', life: 2000});
    },
    searchMeigara() {
      let filteredMeigaras = [];
      this.meigarasearchExtService.getMeigaraInfo(this.selectedMeigara, 0, null)
        .then(d => {
          for(let i=0; i<d.data.length; i++){
            let item = d.data[i].name;
            filteredMeigaras.push(item);
          }
          this.filteredMeigaras = filteredMeigaras;
          // this.perf0 = d.perf;

          this.currentMeigara = this.selectedMeigara;
        }
      );    
    },
    toggle_setMeigaras(event, data, id){
      console.log(id);
      this.$refs.menu1.toggle(event);
      this.copiedMeigaras = data.meigaras;
      // console.log(this.copiedMeigaras);

      this.selectedPropsData = data;
      // this.selectedNewsId = id;
    },
    toggle_MegiaraDisplay(){
      // this.lastclicked_meigara_icon = true;
      if(this.checked_meigara){
        // this.show_meigara = true;
        for(let i=0; i<this.dataviewValue.length; i++) {
          this.dataviewValue[i].show_meigaras = true;
          this.dataviewValue[i].show_meigaras_chart = false;
        }
      }
      else{
        // this.show_meigara = false;
        for(let i=0; i<this.dataviewValue.length; i++) {
          this.dataviewValue[i].show_meigaras = false;
          this.dataviewValue[i].show_meigaras_chart = false;
        }
      }
    },
    toggle_MegiaraDisplay_Each(nidx){
      // this.lastclicked_meigara_icon = false;
      if(this.dataviewValue[nidx].show_meigaras){
        this.dataviewValue[nidx].show_meigaras = false;
      }else{
        this.dataviewValue[nidx].show_meigaras = true;
      }  
    },
    toggle_MegiaraChartDisplay_Each(nidx){
      // this.lastclicked_meigara_icon = false;
      if(this.dataviewValue[nidx].show_meigaras_chart){
        this.dataviewValue[nidx].show_meigaras_chart = false;
      }else{
        this.dataviewValue[nidx].show_meigaras_chart = true;
      }  
    },
    toggle_SearchDisplay(){
      if(this.checked_search){
        this.show_search = true;
      }
      else{
        this.show_search = false;
      }
    },
    toggle_TagDisplay(){
      if(this.checked_tag){
        this.show_tag = true;
      }
      else{
        this.show_tag = false;
      }
    },
    toggle_GridDisplay(){
      if(this.checked_grid){
        this.layout = 'grid';
      }
      else{
        this.layout = 'list';
      }
    },
    toggle_moreNewsType(){
      if(this.moreNewsType){
        this.moreNewsType = false;
      }else{
        this.moreNewsType = true;
      }
    },
    toggle_moreNewsTags(){
      if(this.moreNewsTags){
        this.moreNewsTags = false;
      }else{
        this.moreNewsTags = true;
      }
    },
    toggle_moreMeigaras(){
      if(this.moreMeigaras){
        this.moreMeigaras = false;
      }else{
        this.moreMeigaras = true;
      }
    },
    // toggle_showTrend(){
    //   if(this.showTrend){
    //     this.showTrend = false;
    //   }else{
    //     this.showTrend = true;
    //   }
    // },
    scrollToTop(){
      window.scrollTo(0,0);
    },
    scrollToBottom(){
      window.scrollTo(0,document.body.scrollHeight);
    },
    updateCountDict(){
      this.moreNewsType = true;
      this.moreNewsTags = true;
      this.moreMeigaras = true;

      let arrSentimentValue = [];
      let arrNewsTypeValue = [];
      let arrNewsTagsValue = [];
      let arrMeigarasValue = [];
      let meigaraCdName = [];
      for(let i=0; i<this.dataviewValue.length; i++) {
        arrSentimentValue.push(this.dataviewValue[i].news_sentiment);
        arrNewsTypeValue.push(this.dataviewValue[i].news_type);
        for(let j=0; j<this.dataviewValue[i].news_tags.length; j++) {
          arrNewsTagsValue.push(this.dataviewValue[i].news_tags[j]);
        }
        for(let j=0; j<this.dataviewValue[i].meigaras.length; j++) {
          meigaraCdName = this.dataviewValue[i].meigaras[j].meigara_cd + ' ' + this.dataviewValue[i].meigaras[j].meigara_name;
          arrMeigarasValue.push(meigaraCdName);
        }
      }

      this.countSentimentDict = {};
      for(let key of arrSentimentValue){
        this.countSentimentDict[key] = arrSentimentValue.filter(function(x){return x==key}).length;
      }

      this.countNewsTypeDict = {};
      for(let key of arrNewsTypeValue){
        this.countNewsTypeDict[key] = arrNewsTypeValue.filter(function(x){return x==key}).length;
      }
      this.countNewsTypeDict = Object.entries(this.countNewsTypeDict).sort((a, b) => {
        return a[1] > b[1] ? -1 : 1;
      });

      this.countNewsTagsDict = {};
      for(let key of arrNewsTagsValue){
        this.countNewsTagsDict[key] = arrNewsTagsValue.filter(function(x){return x==key}).length;
      }
      this.countNewsTagsDict = Object.entries(this.countNewsTagsDict).sort((a, b) => {
        return a[1] > b[1] ? -1 : 1;
      });

      this.countMeigarasDict = {};
      for(let key of arrMeigarasValue){
        this.countMeigarasDict[key] = arrMeigarasValue.filter(function(x){return x==key}).length;
      }
      this.countMeigarasDict = Object.entries(this.countMeigarasDict).sort((a, b) => {
        return a[1] > b[1] ? -1 : 1;
      });

      // console.log(this.countSentimentDict);
    }
  }
}
</script>

<style scoped lang="scss">
	@import '../assets/prod/styles/badges.scss';
	@import '../assets/prod/styles/prices.scss';

	a {
    color: #515c66;
    // color: #ffffff;
  }
  a:hover {
    color: #4291dc;
    text-decoration:underline;
  }
  .plus-text {
    color: rgba(255, 0, 0, 0.6);;
    // font-size: 12px;
  }
  .minus-text {
    color: rgba(0, 0, 255, 0.6);;
    // font-size: 12px;
  }
	.subinfo-text {
		color: rgba(81, 92, 102, 0.6);
		font-size: 12px;
	}
  .info-text {
    font-size: 12px;
  }
  .subinfo-link-text {
		// color: #4291dc;
		font-size: 12px;
	}
  .rotate_text {
    transform:rotate(-90deg);
    // width: 10px;
    // height: 1px;
  }
  .date-span {
    color: rgba(81, 92, 102, 0.6);
    font-size: 11px;    
  }
  .trendchart-img {
		opacity: 0.8;
		// border: solid 1px #cccccc;
		border-radius: 5px;  
		width: 182px;
		height: 47px;
	}
  .icon-button-negative {
    color: #2f8ee5;
    background-color: rgba(47, 142, 229, .2);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .icon-button2-negative {
    color: #2f8ee5;
    // background-color: rgba(47, 142, 229, .2);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .icon-button-positive {
    color: #f16383;
    background-color: rgba(241, 99, 131, .2);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .icon-button2-positive {
    color: #f16383;
    // background-color: rgba(241, 99, 131, .2);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .icon-button-neutral {
    color: #f1b263;
    background-color: rgba(241, 178, 99, .2);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .icon-button2-neutral {
    color: #f1b263;
    // background-color: rgba(241, 178, 99, .2);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .icon-button-sm-blue {
    // background-color: rgba(47, 142, 229, 0.2);
    color: #2f8ee5;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    align-items: center;
    justify-content: center;
  }
  .icon-button-sm-red {
    // background-color: rgba(241, 99, 131, 0.2);
    color: #f16383;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    align-items: center;
    justify-content: center;
  }
  .icon-button-sm-yellow {
    // background-color: rgba(241, 178, 99, 0.2);
    color: #f1b263;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    align-items: center;
    justify-content: center;
  }
  .text-active {
    color: #ffffff;
  }
  .a-active {
    background-color: rgba(47, 142, 229, 0.4);
  }
  .chip-highlight {
    background-color: rgba(2, 172, 172, 0.2);
    color: #02acac;
  }
  .span-highlight{
		color: #4291dc;
  }

  .box{
    position: relative;
  }

  .center {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-20%, -50%);
  }
</style>