export default class Meigaranews2listService {
  // getNewsInfo(last_dt, last_id, key, otype) {
  getNewsInfo(last_tid, chunk_size, key, otype) {
    let params = "last_tid=" + last_tid + "&chunk_size=" + chunk_size;
    if(otype == 'meigara'){
      params += "&mcd=" + key;
    }else if(otype == 'type'){
      params += "&type=" + key;
    }else if(otype == 'tag'){
      params += "&tag=" + key;
    }else if(otype == 'sentiment'){
      params += "&sentiment=" + key;
    }

    console.log(params);
    return fetch("https://api.tradersr.com/api/meigaranews2list?" + params)
      .then(res => res.json());
  }
}